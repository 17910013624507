(function ($) {

    $(document).ready(function () {
        var subjectList = $('select[name="your-subject"]')

        if (window.location.search !== '') {
            var searchParams = new URLSearchParams(window.location.search)

            if (searchParams.get('store') !== null) {
                var storeSelect = document.querySelector('select[name="your-shop"]')
                var storeSelectHasOption = storeSelect.querySelector('option[value="' + searchParams.get('store') + '"]')

                if (storeSelectHasOption !== null) {
                    storeSelect.value = searchParams.get('store')
                }
            }
        }

        numberOfCommands(subjectList.val())
        newsletter(subjectList.val())

        subjectList.on('change', function () {
            var selectValue =  $(this).val()
            numberOfCommands(selectValue)
            newsletter(selectValue)
        });
    });

})(jQuery);

function numberOfCommands(value) {
    var field = $('input[name="your-order-number"]').closest('.form-group')

    if (value === 'Question relative à une commande') {
        field.show();
    } else {
        field.hide();
    }
}

function newsletter(value) {
    var field = $('#newsletter').closest('.form-group')

    if (value === 'Question relative à une commande') {
        field.show();
    } else {
        field.hide();
        field.find('input[type="checkbox"]').prop('checked', false)
    }
}